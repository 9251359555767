body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* background-image: linear-gradient(to right bottom, #4bbe79, #47bb7b, #44b87c, #41b47d, #3eb17e, #3db57d, #3cba7c, #3cbe7a, #44ca73, #50d66a, #61e15e, #74ec4e); */
  background: rgb(137, 206, 143);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
